import React, {useEffect, useState} from "react";
import FormField from "../components/items/FormField";
import styled from "styled-components";
import DivProps from "../components/items/DivProps";
import Submit from "../components/items/Submit";
import {theme} from "../utils/theme";
import Container from "../components/items/Container"
import ContainerGlobal from "../components/items/ContainerGlobal"
import Layout from "../components/Layout"
import {getCsrfToken, getSession, signIn} from "next-auth/react";
import Popup from "../components/Popup";
import {useRouter} from "next/router";
import {devices} from "../utils/devices";

export default function Connexion({csrfToken}) {
    const [errorsForm, setErrorsForm] = useState([]);
    const [alert, setAlert] = useState();
    const [closePopup, setClosePopup] = useState(true)
    const router = useRouter()

    useEffect(() => {
        if (!closePopup && alert) {
            setTimeout(() => {
                setClosePopup(true)
                setAlert(null)
            }, 5000)
        }
    }, [closePopup])

    async function signInHandler(event) {
        event.preventDefault();
        let errForm = [];

        if (!event.target.email.value || !event.target.password.value) {
            // errForm.push("Tous les champs sont obligatoires.")
            setAlert(['alert', "Tous les champs sont obligatoires"]);
            setClosePopup(false)
            return null;
        }

        if (errForm.length > 0) {
            setErrorsForm(errForm);
        } else {
            await signIn('credentials', {
                redirect: false, identifier: event.target.email.value, password: event.target.password.value,
            }).then(res => {
                if (res.ok) {
                    router.push("/");
                } else {
                    if (res.status === 401) {
                        setAlert(['alert', "Identifiant ou Mot de passe incorrect"]);
                        setClosePopup(false)
                    } else {
                        setAlert(['alert', "Une erreur est survenue"]);
                        setClosePopup(false)
                    }
                }
            }).catch(err => {
                if (err.message === "Invalid identifier or password") {
                    errForm.push("Identifiant ou Mot de passe incorrect")
                    setErrorsForm(errForm);
                } else {
                    errForm.push("Une erreur est survenue")
                    setErrorsForm(errForm);
                }
            })
        }
    }

    return (<Layout title={`Connexion - To Drink List`}
                    description={`Page de connexion - To Drink List`}
                    isAuth={false}>
        <LoginPage>
            {alert && (<Popup closePopup={closePopup} setClosePopup={setClosePopup} Child={<p>{alert[1]}</p>}
                              bg={alert[0] === "success" ? "#b3e8b3" : "lightcoral"}/>)}
            <Heading>
                <div>
                    <img src={"/images/logo.svg"}/>
                    <p>Vos fiches produits en un clic !</p>
                </div>
            </Heading>

            <Content>
                <div>
                    <div>
                        <img src={"/images/logo.svg"}/>
                    </div>
                    <h2>Connectez-vous à To Drink List</h2>
                    <Form method="post" onSubmit={signInHandler} enctype="multipart/form-data">
                        <input name="csrfToken" type="hidden" defaultValue={csrfToken}/>

                        <FieldGroup>
                            <label htmlFor="email">Email</label>
                            <FormField name="email" id="email" type="email" bg={"greyTransparent"}
                                       placeholder={"exemple@domaine.com"} required/>
                        </FieldGroup>

                        <FieldGroup>
                            <label htmlFor="password"><p>Mot de passe</p><a href={"/changer-de-mot-de-passe"}>Mot de
                                passe oublié ?</a></label>
                            <FormField name="password2" id="password" type="password" bg={"greyTransparent"}
                                       placeholder={"Mot de passe"} required/>
                        </FieldGroup>

                        {errorsForm && errorsForm.length > 0 &&
                            <DivProps displayDesktop={"flex"} displayMobile={"flex"} justifyContent={"flex-start"}
                                      flexDir={"column"} marginDesktop={"2rem 0 0 0"} marginMobile={"2rem 0 0 0"}>
                                {errorsForm.map((err, i) => <Error key={i}>{err}</Error>)}
                            </DivProps>}
                        <DivProps justifyContent={"left"} justifyContentMobile={"center"} widthMobile={"unset"} width={"100%"}>
                            <Submit borderColor={theme.colors.primary} color={"#ffffff"}
                                    colorHover={theme.colors.primary}
                                    bgHover={"#ffffff"} bg={theme.colors.primary}
                                    borderHover={theme.colors.primary} fontSize={"normal"}
                                    marginMobile={"0.8rem 0 0 0"}>
                                Connexion
                            </Submit>
                        </DivProps>
                    </Form>
                </div>
            </Content>
        </LoginPage>
    </Layout>)
}

const LoginPage = styled.div`
  display: flex;
  height: 100vh;

  @media ${devices.maxLaptop} {
    flex-direction: column-reverse;
  }
`

const Heading = styled.div`
  max-width: 35%;
  background-color: #C4D4B1;

  background-image: url("/images/Nicolas_Decarsin_Vineyard.jpg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;

  div {
    padding: 0 2rem;
    font-size: 2rem;
    margin-top: 3rem;
    color: #865C6C;

    img {
      max-width: 185px;
      width: 100%;
    }

    p {
      margin-top: 2.5rem;
    }
  }

  @media ${devices.maxLaptop} {
    max-width: unset;
    max-height: 26%;
    background-size: cover;
    background-position: center;
    height: 100%;
    
    & > div {
      display: none;
    }
  }
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    width: 100%;
    max-width: 500px;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 5rem;
    }

    & > div  {
      display: none;

      img {
        width: 150px;
      }
    }
  }
  
  @media ${devices.maxLaptop} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    
    & > div {
      width: 88%;
      
      h2 {
        margin-bottom: 2rem;
      }
      & > div {
        display: flex;
        justify-content: center;
      }
    }
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  //row-gap: 0.45rem;
  margin-bottom: 1.7rem;
  width: 100%;

  label {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;

    p {
      font-size: .9375rem;
      margin: 0;
    }

    a {
      margin: 0;
      font-size: .875rem;
      font-weight: normal;
      color: #4F3CC9;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const Error = styled.p`
  font-size: 1.6rem;
  background: #ff22005e;
  padding: 0.9rem 2rem;
  border-radius: .5rem;
  color: #ffdad4;
  font-weight: 500;
  width: max-content;
  margin: auto auto .5rem auto;
`

export async function getServerSideProps(context) {
    const session = await getSession(context)

    if (session) {
        return {
            redirect: {
                destination: '/', permanent: false,
            },
        }
    }

    return {
        props: {
            csrfToken: await getCsrfToken(context),
        },
    }
}