import styled from "styled-components";
import {theme} from "../../utils/theme";

export default styled.input`
  border-radius: .5rem;
  border: 1px solid transparent;
  color: black;
  margin-bottom: ${props => props.marginBottom && props.marginBottom};
  background-color: ${props => props.bg ? (theme.colors[props.bg] ?
      theme.colors[props.bg]
      : theme.inputBGColors[props.bg] ? theme.inputBGColors[props.bg] : props.bg) : '#FFFFFF'};

  padding: 1rem;
  ${props => props.padding && "padding: 0.7rem 1rem 0.7rem 1rem;"};
  font-size: ${props => props.fontSize ? theme.fontSize.text[props.fontSize] : theme.fontSize.text.normal};
  width: ${props => props.width && props.width};
  position: ${props => props.position && props.position};
  z-index: ${props => props.zIndex && props.zIndex};
  ${props => props.bgIcon &&
      'background-size: 1.55rem;' +
      'background-repeat: no-repeat;' +
      'background-position:left 1rem center;'
  };

  ${props => props.select && `
    width: available;
    width: fill-available;
    width: -webkit-fill-available;
    width: -moz-available;`
  }
  ${props => props.checkbox && `
    width: auto;
    margin-bottom:0;
   `}
  &::placeholder {
    font-weight: 500;
    color: #a1a1a1;
  }

  &:focus {
    background: #FFFFFF;
    box-shadow: 0 0 0 4px ${theme.colors.primary}15;
    border: 1px solid ${theme.colors.primary}66 !important;
    //border: 1px solid ${theme.colors.hoverField};
    // box-shadow: 0 0 0 4px ${theme.colors.boxShadowRedWine};
  }
`