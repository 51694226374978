import styled from "styled-components";

export default function Popup(props) {
    return !props.closePopup && (
        <AlertPopup bg={props.bg} carouselStyle={props.carouselStyle} formPopup={props.formPopup} formClient={props.formClient} loader={props.loader}>
            <div>
                <img src={"/images/menu/cross-burger.svg"} alt={"Fermer"} onClick={() => props.setClosePopup(true)}/>
                {props.Child && props.Child}
            </div>
        </AlertPopup>
    )
}

const AlertPopup = styled.div`
  position: fixed;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%); 
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${props => props.carouselStyle ? 'rgba(0, 0, 0, 0.92)' : 'rgba(0,0,0,0.54)'};
  margin: 0 !important;
  z-index: 121;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;


  & > div {
    position: relative;
    background-color: ${props => props.bg ? props.bg : ''};
    
    ${props => !props.carouselStyle && !props.formPopup && !props.formClient ? `
        padding: 2rem 3rem;
        margin: 2rem;
        border-radius: 1rem;
        font-weight: 800;
        opacity: 0.95;
        height: auto;
        display: flex;
        align-items: center;
        ` : ''
    }
    ${props => props.formPopup ? `
        display: flex;
        justify-content: center;
        width: 90%;
        height: 90vh;
        ` : ''
    }
    ${props => props.formClient ? `
        display: flex;
        justify-content: center;
        width: 90%;
        height: auto;
        max-width:600px;
        opacity: 1;
        ` : ''
    }
    ${props => props.formDelete ? `
        height: auto;
        ` : ''
    }
    // width: ${props => props.form ? '90%' : ''};
    

    & > img {
      display: ${props => props.loader && "none"};
      
      position: absolute;
      top: ${props => !props.carouselStyle ? '10px;' : '25px;'}
      right: ${props => !props.carouselStyle ? '16px;' : '20px;'}
      width: ${props => !props.carouselStyle ? '24px;' : '40px;'}
      height: ${props => !props.carouselStyle ? '24px;' : '40px;'}
      z-index: 100;
      cursor: pointer;
      
      &:hover {
          transform: scale(1.2)
      }
    }
  }
`
